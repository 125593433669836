import React from 'react'
import { gaCtaButtonClickEvent } from '../../utils/Analytics'

import { Container, Link } from './CtaButtonStyled'

import { useMainContext } from '../../context/MainContext'

function CtaButton({ button }) {
  const { showcase } = useMainContext()

  const { id: showcaseId, ga4_id, instagram_layout } = showcase
  const { id, text, url, css_style, css_hover } = button

  const showEmphasis = instagram_layout === 'profile'

  return (
    <Container showEmphasis={showEmphasis} styles={css_style} hover={css_hover}>
      <Link
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => gaCtaButtonClickEvent(showcaseId, ga4_id, id)}
      >
        {text}
      </Link>
    </Container>
  )
}

export default CtaButton
