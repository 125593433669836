import React from 'react'
import { gaPostClickEvent } from '../../utils/Analytics'

import VideoImage from '../../assets/video.svg'
import CarrosselImage from '../../assets/carrossel.svg'

import { Container, Link, Image, Subtitle, MediaType } from './PostStyled'

import { useMainContext } from '../../context/MainContext'

function Post({ post }) {
  const { showcase } = useMainContext()

  const { id: showcaseId, ga4_id, instagram_layout } = showcase

  const { instagram_media_id, item_value, url_custom, media_url, media_type } =
    post

  const customMediaTypes = ['VIDEO', 'CAROUSEL_ALBUM']

  const getMediaTypeImage = type =>
    ({
      VIDEO: VideoImage,
      CAROUSEL_ALBUM: CarrosselImage,
    }[type] || '')

  const renderSubtitle = () => {
    if (instagram_layout === 'profile' || item_value.trim().length < 1) return

    return (
      <Subtitle data-testid={`subtitle-${instagram_media_id}`}>
        {item_value}
      </Subtitle>
    )
  }

  const renderCustomMediaTypeIcon = () =>
    customMediaTypes.includes(media_type) ? (
      <MediaType>
        <img src={getMediaTypeImage(media_type)} alt={media_type} />
      </MediaType>
    ) : null

  return (
    <Container
      show-emphasis={instagram_layout === 'default'}
      data-testid={instagram_media_id}
    >
      <div>
        <Link
          href={url_custom}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            gaPostClickEvent(showcaseId, ga4_id, instagram_media_id)
          }
        >
          <Image src={media_url} />
          {renderCustomMediaTypeIcon()}

          {renderSubtitle()}
        </Link>
      </div>
    </Container>
  )
}

export default Post
