/* global ga */
const event_category = 'RD Showcase'
const g4_event_name = 'RD Link da Bio'

const gaInstall = (ga_id, showcase_identifier, version) => {
  if (version === 3) {
    installGA3(ga_id, showcase_identifier)
  }

  if (version === 4) {
    installGA4(ga_id, showcase_identifier)
  }
}

//GA4 Events
const gaPostClickEvent_GA4 = (showcaseIdentifier, postId) => {
  gtag(`event`, g4_event_name, {
    rd_asset_id: showcaseIdentifier,
    rd_filter: `post:${postId}`,
    rd_action: 'clicked',
  })
}

const gaCtaButtonClickEvent_GA4 = (showcaseIdentifier, ctaButtonId) => {
  gtag(`event`, g4_event_name, {
    rd_asset_id: showcaseIdentifier,
    rd_filter: `cta:${ctaButtonId}`,
    rd_action: 'clicked',
  })
}

const gaSocialClickEvent_GA4 = (showcaseIdentifier, social) => {
  gtag(`event`, g4_event_name, {
    rd_asset_id: showcaseIdentifier,
    rd_filter: `social:${social}`,
    rd_action: 'clicked',
  })
}

//GA3 Events
const gaPostClickEvent = (showcaseId, showcaseIdentifier, postId) => {
  window.ga &&
    ga(
      'send',
      'event',
      event_category,
      'Clicked',
      `bio:${showcaseId}:post:${postId}`,
    )
  gaPostClickEvent_GA4(showcaseIdentifier, postId)
}

const gaCtaButtonClickEvent = (showcaseId, showcaseIdentifier, ctaButtonId) => {
  window.ga &&
    ga(
      'send',
      'event',
      event_category,
      'Clicked',
      `bio:${showcaseId}:cta:${ctaButtonId}`,
    )
  gaCtaButtonClickEvent_GA4(showcaseIdentifier, ctaButtonId)
}

const gaSocialClickEvent = (showcaseId, showcaseIdentifier, social) => {
  window.ga &&
    ga(
      'send',
      'event',
      event_category,
      'Clicked',
      `bio:${showcaseId}:social:${social}`,
    )
  gaSocialClickEvent_GA4(showcaseIdentifier, social)
}

const gtag = function () {
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  window.dataLayer.push(arguments)
}

const installGA4 = (ga_id, showcase_identifier) => {
  const scriptId = `ga-gtag-${ga_id}`

  if (document.getElementById(scriptId)) return

  const { head } = document
  const script = document.createElement('script')

  script.id = scriptId
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${ga_id}`

  head.insertBefore(script, head.firstChild)

  window.dataLayer = window.dataLayer || []
  gtag('js', new Date())
  gtag('config', ga_id)
  gtag('event', g4_event_name, {
    rd_asset_id: showcase_identifier,
    rd_filter: '',
    rd_action: 'viewed',
  })
}

const installGA3 = (ga_id, showcase_identifier) => {
  const scriptId = `ga-gtag-${ga_id}`

  if (document.getElementById(scriptId)) return

  const { head } = document
  const script = document.createElement('script')

  script.id = scriptId
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://www.google-analytics.com/analytics.js`

  head.insertBefore(script, head.firstChild)

  window.ga =
    window.ga ||
    function () {
      ;(ga.q = ga.q || []).push(arguments)
    }
  ga.l = +new Date()
  ga('create', ga_id, 'auto')
  ga('send', 'pageview')
  ga('send', 'event', event_category, 'Viewed', `bio:${showcase_identifier}`)
}

export {
  gaInstall,
  gaPostClickEvent,
  gaCtaButtonClickEvent,
  gaSocialClickEvent,
}
